import React, { useState, useEffect, useMemo } from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import { Title, Columns, Column, Button } from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))

function CountryNameGenerator(props) {
  const { node } = props.pageContext
  let [result1, setresult1] = useState([])

  useEffect(() => {
    randomizer(node.country)
  }, [])

  const generate = () => {
    randomizer(node.country)
  }

  const randomizer = list => {
    let NamesData = []
    for (let i = 0; i < 6; i++) {
      let random = Math.floor(Math.random() * list.length)
      NamesData.push({
        Name: list[random].Name,
        Code: list[random].Code,
      })
    }
    setresult1(NamesData)
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`Random Country Name Generator`}
        description={`Random Country Name Generator, Simple generate random country names, and country-code online.`}
        keywords={[
          "Country name,Country Name generator,Random name of Country,Random Country online, random Country name generator, random Country name,make Country name, Country generator, random Country name and code, random Country code names, random code Country names,random Country names",
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <div className="container">
        <Title>Random Country Name Generator</Title>
        <br />
        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>
              <br />
              <div>
                {result1.map((e, index) => {
                  return (
                    <p className="h5" key={index}>
                      Country: {e.Name}
                      <br />
                      Code: {e.Code}
                    </p>
                  )
                })}
              </div>
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <FlexDiv maxWidth="250px" margin="auto">
          <Img
            fluid={node.img.childImageSharp.fluid}
            alt="random country names generator"
          />
        </FlexDiv>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </div>
    </Layout>
  )
}

export default CountryNameGenerator
